@import '../../../../scss/theme-bootstrap';

.product-fave-confirm {
  padding: 3.75em 2em;
  text-align: center;
  &__header {
    @include h9;
    margin-top: 0;
    margin-bottom: 0.5em;
    min-height: 0.5em;
    color: $color_green;
  }
  &__copy {
    @include t4;
  }
  &__cta {
  }
}
